import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import {
    Container,
    Card
} from 'reactstrap';
import Link from "next/link"
import styles from './styles.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay, EffectFade } from "swiper"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

export default function FocusValuesSlider({
    global,
    pathPrefix,
    customClass = "",
    post,
    swiperID
}) {
    const settings = {
        modules: [Navigation, Pagination, Autoplay, EffectFade],
        loop: false,
        spaceBetween: 30,
        speed: 500,
        // effect: 'fade',
        // fadeEffect: {
        //     crossFade: true
        // },
        autoplay: {
            delay: 5000,
        },
        pagination: {
            clickable: true,
            el: `.${swiperID}.swiper-custom-paginations`,
        },
        // navigation: {
        //     nextEl: `.${swiperID}.swiper-next`,
        //     prevEl: `.${swiperID}.swiper-prev`
        // },
        breakpoints: {
            1200: {
                slidesPerView: 4,
            },
            991: {
                slidesPerView: 3,
            },
            767: {
                slidesPerView: 2,
            },

        }
    }

    return (
        post?.length >= 1 && (
            <section className={`${styles.root} ${customClass}`}>
                <Container>
                    <Swiper {...settings}>
                        {post.map((post, index) =>
                            <SwiperSlide key={index}>
                                <Card className="border-0 bg-transparent d-block mt-3">
                                    <h3 className="fs-1">{post?.title}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: post?.content }} />
                                </Card>
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <div className="text-center mt-4">
                        <div className={`${swiperID} swiper-custom-paginations`} />
                    </div>
                </Container>
            </section>
        )

    )
}